import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { url } from 'inspector';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/common/global-constants';
import { FilterArrPipe } from 'src/app/pipes/filter-arr.pipe';
import { SortByPipe } from 'src/app/pipes/sort-by.pipe';
import { DataService } from 'src/app/services/data-service.service';
import { FunctionServiceService } from 'src/app/services/function-service.service';

@Component({
  selector: 'app-filter-vr',
  templateUrl: './filter-vr.page.html',
  styleUrls: ['./filter-vr.page.scss'],
})
export class FilterVRPage implements OnInit {
  userData;isAdmin;resource;data;totalWallet;isLoading;date;
  myForm:FormGroup;filterArr = new FilterArrPipe;userType:any;sort = new SortByPipe();
  isWide;states = [];country = [];allStates:any = [];url:any;
  constructor(
    private ds:DataService,
    private fn:FunctionServiceService,
    private modalCtrl:ModalController,
    private fb:FormBuilder,
    private alertCtrl:AlertController,
    private storage:Storage 
  ) {
    this.url = this.fn.getUrlPath();
  }
  ngOnInit() {
    this.reactiveForm();
    if(this.data){
      this.myForm.patchValue(this.data)
    }
    this.ds.getProfileValue().subscribe((data)=>{
      this.userData = data;
    })
    this.ds.getResValue().subscribe((data)=>{
      this.resource = data;
      if(this.resource.status){
      }
    })
    this.ds.getStateCall();
    this.ds.getState().subscribe((data:any)=>{
      this.allStates = data;
    })
    this.setState({value:'161'});
  }
  reactiveForm(){
    this.myForm = this.fb.group({
      'v_status': ['',[]],
      'status': ['',[]],
      'type': ['',[]],
      //'state': ['',[]],
    });
  }
  setState(event,type?){
    this.states = [];
    //get states from value
    if(event.value){
      this.states = this.allStates.filter(element=>{
        var value = (element.country_id==event.value)
        return value;
      })
      if(this.states.length>0){
        this.states = this.sort.transform(this.states,'ASC','name')
      }
    }
  }
  public errorHandling = (control: string, error: string) => {
    if(this.myForm && this.myForm.controls && this.myForm.controls[control])
    return this.myForm.controls[control].hasError(error);
  }
  closeModal(data?){
    this.modalCtrl.dismiss(data);
  }
  submit(){
    if(this.fn.isEmpty(this.myForm.value)){
      this.fn.presentToast('No search parameter was provided')
      return;
    }
    this.closeModal(this.myForm.value)
  }
}
