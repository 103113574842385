import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data-service.service';
import { FunctionServiceService } from 'src/app/services/function-service.service';
import { ReloadServiceService } from 'src/app/services/reload-service.service';

@Component({
  selector: 'app-opt-out',
  templateUrl: './opt-out.page.html',
  styleUrls: ['./opt-out.page.scss'],
})
export class OptOutPage implements OnInit {
  myForm:FormGroup;userData;isLoading;
  notes = [
    'When you decide to opt out of the Co-Funding Arrangement, we must commit to refunding already saved money on the platform within 72 hours after confirmation to opt out',
    'A non-refundable admin fee of 2% (more or less) should apply.',
  ];
  reasons = [
    'I have raised fund from other sources',
    'I have decided to withdraw or defer admission ',
    'I am unable to commit to the savings plan to the end',
    'Bad customer service from PressPay',
    'The time duration will not meet my school fees deadline ',
    'other',
  ];
  constructor(
    private fb:FormBuilder,
    private ds:DataService,
    private fn:FunctionServiceService,
    private rl:ReloadServiceService
  ) { }

  ngOnInit() {
    this.reactiveForm();
    this.ds.getProfileValue().subscribe((data)=>{
      this.userData = data;
      this.myForm.patchValue({remail:data.email,rname:data.full_name})
    })
  }
  reactiveForm(){
    this.myForm = this.fb.group({
      'type':['',[Validators.required]],
      'remark':['',[Validators.minLength(20)]],
    })
  }
  public errorHandling = (control: string, error: string) => {
    if(this.myForm && this.myForm.controls && this.myForm.controls[control])
    return this.myForm.controls[control].hasError(error);
  }
  submit(){
    if(this.myForm.valid){
      if(this.myForm.value.type=='other' && !this.myForm.value.remark){
        this.fn.loadAlert('Please provide a reason')
        return;
      }
      this.isLoading = true;
      this.ds.optOut(this.myForm.value).subscribe((data:any)=>{
        this.isLoading = false;
        if(data.status){
          this.fn.loadAlert(data.message,'Thank you, all Done!');
          this.closeModal();
        }
        else{
          this.fn.loadAlert(data.message,'Eh yah, sorry!')
        }
      },error=>{
        this.isLoading = false;
        this.fn.loadError(error);
      })
    }
  }
  closeModal(){
    this.fn.closeModal();
  }

}
