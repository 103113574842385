import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data-service.service';
import { FunctionServiceService } from 'src/app/services/function-service.service';

@Component({
  selector: 'app-verify-rrr',
  templateUrl: './verify-rrr.page.html',
  styleUrls: ['./verify-rrr.page.scss'],
})
export class VerifyRRRPage implements OnInit {
  data;myForm:FormGroup;isLoading;rrrData;
  constructor(
    private ds:DataService,
    private fn:FunctionServiceService,
    private fb:FormBuilder
  ) { }

  ngOnInit() {
    this.reactiveForm();
    //console.log(this.data)
    if(this.data?.rrr){
      this.myForm.patchValue({rrr:this.data?.rrr})
    }
  }
  closeModal(){
    this.fn.closeModal();
  }
  reactiveForm(){
    this.myForm = this.fb.group({
      'rrr': ['',[Validators.required]],
    });
  }
  public errorHandling = (control: any, error: string) => {
    if(this.myForm && this.myForm.controls && this.myForm.controls[control])
    return this.myForm.controls[control].hasError(error);
  }
  verify(){
    this.rrrData = '';
    var item = this.data;
    if(!this.myForm?.value?.rrr){
      this.fn.loadAlert('RRR is not provided')
      return;
    }
    if(this.isLoading)
    return;
    this.isLoading = true;
    this.ds.verifyRRR(this.myForm?.value?.rrr).subscribe((data:any)=>{
      this.rrrData = data;
      this.isLoading = false;
      console.log(this.rrrData?.data?.responseData)
      this.fn.loadAlert(data?.data?.responseMsg);
      console.log(data)
    },error=>{
      this.isLoading = false;
    })
  }
  submit(){
    var obj = {
      'transactionId':'67789020022',
      'statusCode':'555',
      'amount':'500',
      'rrr':this.myForm?.value?.rrr,
    };
    if(this.isLoading)
    return;
    this.isLoading = true;
    this.ds.sendRRR(obj).subscribe((data:any)=>{
      this.isLoading = false;
      this.fn.loadAlert(data?.data?.responseMsg);
    },error=>{
      this.isLoading = false;
    })
  }

}
