import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-message',
  templateUrl: './message.page.html',
  styleUrls: ['./message.page.scss'],
})
export class MessagePage implements OnInit {
  data:any;
  constructor(
    private modalCtrl:ModalController
  ) { }

  ngOnInit() {
    console.log(this.data)
  }
  closeModal(){
    this.modalCtrl.dismiss(true)
  }

}
