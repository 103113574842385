import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from 'src/app/common/global-constants';
import { DataService } from 'src/app/services/data-service.service';
import { FunctionServiceService } from 'src/app/services/function-service.service';
import { ReloadServiceService } from 'src/app/services/reload-service.service';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.page.html',
  styleUrls: ['./refer.page.scss'],
})
export class ReferPage implements OnInit {
  myForm:FormGroup;userData;isLoading;resource:any;
  constructor(
    private fb:FormBuilder,
    private ds:DataService,
    private fn:FunctionServiceService,
    private rl:ReloadServiceService
  ) { }

  ngOnInit() {
    this.reactiveForm();
    this.ds.getProfileValue().subscribe((data)=>{
      this.userData = data;
      this.myForm.patchValue({remail:data.email,rname:data.full_name})
    })
    this.ds.getResValue().subscribe((data:any)=>{
      this.resource = data;
    })
  }
  reactiveForm(){
    this.myForm = this.fb.group({
      'rname':['',[Validators.required]],
      'remail':['',[Validators.required,Validators.email]],
      'fname':['',[Validators.required]],
      'femail':['',[Validators.required,Validators.email]],
    })
  }
  public errorHandling = (control: string, error: string) => {
    if(this.myForm && this.myForm.controls && this.myForm.controls[control])
    return this.myForm.controls[control].hasError(error);
  }
  submit(){
    if(this.myForm.valid){
      this.isLoading = true;
      this.ds.refer(this.myForm.value).subscribe((data:any)=>{
        this.isLoading = false;
        if(data.status){
          this.fn.loadAlert(data.message,'Thank you, all Done!');
          this.closeModal();
        }
        else{
          this.fn.loadAlert(data.message,'Eh yah, sorry!')
        }
      },error=>{
        this.isLoading = false;
        this.fn.loadError(error);
      })
    }
  }
  closeModal(){
    this.fn.closeModal();
  }
  share(){
    this.fn.share(this.resource?.data?.txtRefer,GlobalConstants.appUrl+'sign-up?id='+this.userData?.user?.referral_id)
  }

}
