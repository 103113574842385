import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data-service.service';
import { FunctionServiceService } from 'src/app/services/function-service.service';

@Component({
  selector: 'app-set-pin',
  templateUrl: './set-pin.page.html',
  styleUrls: ['./set-pin.page.scss'],
})
export class SetPinPage implements OnInit {
  myForm:FormGroup;isLoading
  constructor(
    private ds:DataService,
    private fn:FunctionServiceService,
    private modalCtrl:ModalController,
    private fb:FormBuilder
  ) { }

  ngOnInit() {
    this.reactiveForm();
    setTimeout(()=>{
      this.myForm.patchValue({password:''})
    },1000)
  }
  reactiveForm(){
    this.myForm = this.fb.group({
      'pin': ['',[Validators.required,Validators.minLength(4),Validators.maxLength(4)]],
      'password': ['',[Validators.required,Validators.minLength(6)]],
    });
  }
  public errorHandling = (control: string, error: string) => {
    if(this.myForm && this.myForm.controls && this.myForm.controls[control])
    return this.myForm.controls[control].hasError(error);
  }
  closeModal(data?){
    this.modalCtrl.dismiss(data);
  }
  submit(){
    if(this.myForm.valid){
      this.isLoading = true;
      this.ds.setPin(this.myForm.value).subscribe((data:any)=>{
        this.isLoading = false;
        if(data.status){
          this.fn.loadAlert(data.message,'All Set!')
          this.closeModal(true)
        }else{
          this.fn.loadAlert(data.message,'Not good news!')
        }
      },error=>{
        this.isLoading = false;
        this.fn.loadError(error)
      })
    }
  }

}
